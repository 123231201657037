<template>
    <Navbar />
    <BlockUI :blocked="PageBlocked">
        <ScrollPanel style="width: 100%;" :style="{ 'height': windowHeight + 'px' }">
            <br />
            <div class="p-d-flex p-jc-center">
                <Card v-if="DahaOnceYapildi" style="margin-top:10vh; width: 40rem; overflow: hidden">
                    <template #title>
                        <div class="p-d-flex p-jc-center"><i class="pi pi-check-circle"
                                :style="{ fontSize: '5rem', color: 'var(--green-500)' }"></i></div>
                    </template>
                    <template #content>
                        <div class="p-grid p-fluid p-formgrid p-d-flex p-jc-center">
                            <div class="p-field p-col-11 ">
                                <p class="p-text-center">
                                    <b>Bu talep için daha önce değerlendirme yapıldı</b>
                                </p>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>
            <div class="p-d-flex p-jc-center">
                <Card v-if="!DahaOnceYapildi" style="margin-top:10vh; width: 40rem; overflow: hidden">
                    <template #title>
                        <div class="p-d-flex p-jc-center">Gelsin Teknik Memnuniyet Anketi</div>
                    </template>
                    <template #content>
                        <div class="p-grid p-fluid p-formgrid p-d-flex p-jc-center">
                            <div class="p-field p-col-11">
                                <p>

                                    <b>Değerli Müşterimiz,</b><br /><br />
                                    Gelsin Teknik olarak size en iyi hizmeti sunmak için sürekli olarak kendimizi
                                    geliştirmeye
                                    çalışıyoruz. Hizmetlerimizle ilgili görüşlerinizi bizimle paylaşarak bu süreçte bize
                                    yardımcı
                                    olabilirsiniz. Aşağıdaki kısa anketi doldurarak memnuniyetinizi değerlendirin.
                                    Görüşleriniz
                                    bizim için çok değerli.

                                </p>
                            </div>
                            <div class="p-field p-col-10">
                                <div class="p-grid p-fluid p-formgrid p-d-flex p-jc-center">
                                    <!-- Soru_ 1 -->
                                    <div class="p-field p-col-8"> Hizmetimizin genel kalitesini nasıl
                                        değerlendirirsiniz?
                                    </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_1" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 2 -->
                                    <div class="p-field p-col-8"> Kurye hizmetimizden memnun kaldınız mı? </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_2" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 3 -->
                                    <div class="p-field p-col-8"> Cihazınızın onarım sürecinden memnun kaldınız mı?
                                    </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_3" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 4 -->
                                    <div class="p-field p-col-8"> Talebinizin durumu hakkında bilgilendirme sürecinden
                                        memnun kaldınız mı?</div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_4" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 5 -->
                                    <div class="p-field p-col-8"> Kurye hizmetimizden memnun kaldınız mı? </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_5" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 6 -->
                                    <div class="p-field p-col-8"> Cihazınızın onarım sürecinden memnun kaldınız mı?
                                    </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_6" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 7 -->
                                    <div class="p-field p-col-8"> Talebinizin durumu hakkında bilgilendirme sürecinden
                                        memnun kaldınız mı?</div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_7" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 8 -->
                                    <div class="p-field p-col-8"> Hizmetimizin fiyatlandırmasını nasıl
                                        değerlendirirsiniz?
                                    </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_8" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 9 -->
                                    <div class="p-field p-col-8"> Hizmet sonrası destekten memnun kaldınız mı?</div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_9" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 10 -->
                                    <div class="p-field p-col-8"> Gelsin Teknik'i genel olarak nasıl değerlendirirsiniz?
                                    </div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_10" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 11 -->
                                    <div class="p-field p-col-12"> Hizmetimizi geliştirmek için önerileriniz var mı?
                                        Lütfen
                                        paylaşın.</div>
                                    <div class="p-field p-col-10 p-d-flex p-jc-center">
                                        <Textarea v-model="AnketList.Soru_11" variant="filled" rows="2" />
                                    </div>
                                    <!-- Soru_ 12 -->
                                    <div class="p-field p-col-8"> Gelsin Teknik’i tekrar tercih eder misiniz?</div>
                                    <div class="p-field p-col-4">
                                        <Rating v-model="AnketList.Soru_12" :stars="5" :cancel="false" />
                                    </div>
                                    <!-- Soru_ 13 -->
                                    <div class="p-field p-col-12"> Gelsin Teknik’i Nereden buldunuz? (Birden fazla
                                        seçebilirsiniz)</div>
                                    <div class="p-field p-col-12 p-d-flex ">

                                        <div class="card flex justify-content-center">
                                            <div class="flex flex-column gap-3">
                                                <div v-for="NeredenBuldu of NeredenBuldus" :key="NeredenBuldu.key"
                                                    class="flex align-items-center p-m-2">
                                                    <Checkbox v-model="selectedNeredenBuldus"
                                                        :inputId="NeredenBuldu.key" name="NeredenBuldu"
                                                        :value="NeredenBuldu.name" />
                                                    <label :for="NeredenBuldu.key">&nbsp;{{ NeredenBuldu.name }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Transition name="bounce">
                                        <div v-if="NeredenBulduText_Show" class="p-field p-col-10 p-d-flex p-jc-center">
                                            <div class="inner">
                                                <Textarea v-model="AnketList.Soru_13_Aciklama" variant="filled"
                                                    rows="2" />
                                            </div>
                                        </div>
                                    </Transition>
                                    <div class="p-field p-col-11 p-d-flex p-jc-center">
                                        <p> <b>Görüşlerinizi bizimle paylaştığınız için teşekkür ederiz! </b></p>
                                    </div>
                                    <div class="p-field p-col-11 p-d-flex p-jc-center">
                                        <Button label="Gönder" severity="help" @click="_Gonder()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                </Card>
            </div>
        </ScrollPanel>
    </BlockUI>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";
import Navbar from "../navbar.vue";
export default {
    data() {
        return {
            DahaOnceYapildi: false,
            PageBlocked: false,
            windowHeight: window.innerHeight,
            NeredenBulduText_Show: false,
            AnketList: {
                Soru_1: 1,
                Soru_2: 1,
                Soru_3: 1,
                Soru_4: 1,
                Soru_5: 1,
                Soru_6: 1,
                Soru_7: 1,
                Soru_8: 1,
                Soru_9: 1,
                Soru_10: 1,
                Soru_11: "", // Öneri Text
                Soru_12: 1,
                Soru_13: [],
                Soru_13_Aciklama: "" // Diğer, Nereden buldu ? Acaba ? o.O
            },
            selectedNeredenBuldus: [],
            NeredenBuldus: [
                { name: "İnternet Araması", key: "I" },
                { name: "Sosyal Medya", key: "S" },
                { name: "Arkadaş Tavsiyesi", key: "A" },
                { name: "Diğer", key: "D" }
            ]
        };
    },
    methods: {
        InitServis() {
            if (this.$route.query.TalepNo == "" || typeof (this.$route.query.TalepNo) === "undefined") {
                this.$router.push({ path: "/NotFound" })
            }
            this._TalepDegerlendirmeSorgulama();
        },
        _Gonder() {
            this.AnketList.Soru_13 = this.selectedNeredenBuldus;
            let check_NeredenBuldu = "";
            this.AnketList.Soru_13.forEach(element => {
                if (check_NeredenBuldu == "") {
                    check_NeredenBuldu = element;
                }
                else {
                    check_NeredenBuldu = check_NeredenBuldu + ',' + element;
                }
            });
            let data = {
                Barkod: this.$route.query.TalepNo,
                Soru_1: this.AnketList.Soru_1,
                Soru_2: this.AnketList.Soru_2,
                Soru_3: this.AnketList.Soru_3,
                Soru_4: this.AnketList.Soru_4,
                Soru_5: this.AnketList.Soru_5,
                Soru_6: this.AnketList.Soru_6,
                Soru_7: this.AnketList.Soru_7,
                Soru_8: this.AnketList.Soru_8,
                Soru_9: this.AnketList.Soru_9,
                Soru_10: this.AnketList.Soru_10,
                Soru_11: this.AnketList.Soru_11, // Öneri Text
                Soru_12: this.AnketList.Soru_12,
                Soru_13: check_NeredenBuldu,
                Soru_13_Aciklama: this.AnketList.Soru_13_Aciklama
            }

            GlobalServis.GlobalServis("POST", "SetAnket", data).then((res) => {
                if (res.status == 200) {
                    this.$toast.add({
                        severity: "success",
                        summary: "Teşekkürler",
                        detail: "Değerlendirmeniz Alınmıştır.",
                        life: 3000,
                    });
                    this.PageBlocked = true;
                    setTimeout(() => { this.$router.push({ path: '/Anket/Basarili' }); }, 2000);

                } else if (res.response.status == 999) {
                    this.$toast.add({
                        severity: "warn",
                        summary: "Uyarı",
                        detail: res.response.data,
                        life: 3000,
                    });
                } else if (res.status == 400) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Önemli bir hata meydana geldi",
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Bilinmeyen bir hata meydana geldi",
                        life: 3000,
                    });
                }
            });

        },
        _TalepDegerlendirmeSorgulama() {
            let data = {
                Barkod: this.$route.query.TalepNo,
            };

            GlobalServis.GlobalServis("POST", "GetAnketKontrol", data).then((res) => {
                if (res.status == 200) {
                    this.DahaOnceYapildi = false;
                } else if (res.response.status == 999) {
                    this.$toast.add({
                        severity: "warn",
                        summary: "Uyarı",
                        detail: res.response.data,
                        life: 3000,
                    });
                    this.DahaOnceYapildi = true;
                } else if (res.status == 400) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Önemli bir hata meydana geldi",
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Bilinmeyen bir hata meydana geldi",
                        life: 3000,
                    });
                }
            });
        },
        onResize() {
            this.windowHeight = window.innerHeight
        }
    },
    created() {
        this.InitServis();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    watch: {
        selectedNeredenBuldus: function (val, oldVal) {
            if (val.find((element) => element == "Diğer") === "Diğer") {
                this.NeredenBulduText_Show = true;
            } else {
                this.NeredenBulduText_Show = false;
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    mounted() {
    },
    components: {
        Navbar
    },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
    ::v-deep(.customized-timeline) {
        .p-timeline-event:nth-child(even) {
            flex-direction: row !important;

            .p-timeline-event-content {
                text-align: left !important;
            }
        }

        .p-timeline-event-opposite {
            flex: 0;
        }

        .p-card {
            margin-top: 1rem;
        }
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}
</style>